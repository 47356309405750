/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('ul.sub-menu').parent('li').addClass('has_subnav').children('a').after('<span class="toggle-link"></span>');

        $( '.has_subnav > a' ).on("click", function(e){ e.preventDefault(); } );

        $( '.toggle-link' ).click( function( e ) {
          $(this).toggleClass( 'active' ).siblings( 'ul' ).toggleClass( 'open' ).parent( 'li' ).toggleClass( 'subnav_open' );
        });

        // Fixed sidebar scroll on Venues/Pacakges pages
        function fixed_sidebar() {
          var sidebar = $('.sidebar'),
          proactivist = $('.sidebar .widget__item'),
          sidebarTopPosition,
          offsetScrollPosition,
          maxScrollPosition;

          $(window).load(function() {
            if (sidebar.length === 1) { // only trigger if there is 1 sidebar on screen
              calculateSidebarValues();
              setSidebarClasses();
            }
          });

          $(window).resize(function() {
            if (sidebar.length === 1) {
              calculateSidebarValues();
              setSidebarClasses();
            }
          });

          $(window).scroll(function() {
            if (sidebar.length === 1) {
              setSidebarClasses();
            }
          });

          function calcOffsetScroll() {
            // Offset is window top plus the current header height and some extra padding
            offsetScrollPosition = $(window).scrollTop() + 80;

            if ($('#wpadminbar').length) {
              // Account for WP Admin bar
              offsetScrollPosition += $('#wpadminbar').outerHeight();
            }
          }

          function calculateSidebarValues() {
            // Page position of the start of the sidebar
            sidebarTopPosition = sidebar.offset().top;

            // Maximum position to prevent the sidebar from overlapping the next component
            maxScrollPosition = sidebarTopPosition + sidebar.outerHeight() - proactivist.outerHeight() - 50;
          }

          function setSidebarClasses() {
            calcOffsetScroll();

            if (offsetScrollPosition >= maxScrollPosition) {
              // Set classes if we've scrolled past the fixed sidebar section
              proactivist.css('top', '');
              proactivist.removeClass('sidebar-item--fixed');
              sidebar.addClass('content-sidebar--end');
            } else if (offsetScrollPosition >= sidebarTopPosition && offsetScrollPosition < maxScrollPosition && !proactivist.hasClass('sidebar-item--fixed')) {
              // Set classes if we're scrolling through the fixed sidebar section
              proactivist.css('top', 80);
              proactivist.addClass('sidebar-item--fixed');
              sidebar.removeClass('content-sidebar--end');
            } else if (offsetScrollPosition < sidebarTopPosition) {
              // Set classes if we're above the fixed sidebar section
              proactivist.removeClass('sidebar-item--fixed');
              sidebar.removeClass('content-sidebar--end');
            }
          }
        }

        fixed_sidebar();

        function triggerNewsletter() {
          const SPL_NOT_DEFINED = !window.SPOTLIGHT;
          if(SPL_NOT_DEFINED) {
            return;
          }

          SPOTLIGHT.registerNewsletter();
        }

        setTimeout(function() { triggerNewsletter(); }, 30000);
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        function inject_images() {
          $('.soliloquy-active-slide .soliloquy-image').each(function(i, e) {
            cta = $(e).parent().find('.blurred-image-container');
            cta.css( 'background-image', 'url(' + $(e).attr( 'src' )  + ')' );
            width = $(e).width();
            height = $(e).height();
            cta.css( 'background-size', width + 'px ' + height + 'px');
          })
        }

        inject_images();

        setInterval(inject_images, 50);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $('.cff-author-img img').attr( 'src', 'https://scontent-lhr3-1.xx.fbcdn.net/v/t1.0-1/p320x320/12036474_1116444565086176_6044918613702735214_n.jpg?oh=0d0485b293f8b049b8299cc52014df2f&oe=59F5A62B' );
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $('.share-buttons .cta, .nav-button').click(function(event){
    if ( !$(event.target).hasClass('open')) {
      $(event.target).addClass('open');
      $(event.target).parent().addClass('open');
      $(event.target).parent().find('.share-buttons, .nav-hero').addClass('open');
    } else {
      $(event.target).removeClass('open');
      $(event.target).parent().removeClass('open');
      $(event.target).parent().find('.share-buttons, .nav-hero').removeClass('open');
    }
  });

  // play video immediately on click icon play
  $('.soliloquy-video-icon').on('click', function(){
    $('video').get(0).play();
  });

    document.querySelectorAll('a[div-id].open-checkout-inline').forEach(function(btns){btns.addEventListener('click', function(event){
        var divId = event.target.getAttribute('div-id');
        var anchorIframeWrapper = document.querySelector('div.' + divId);
        if (anchorIframeWrapper.classList.contains("open")) {
          anchorIframeWrapper.classList.toggle("hide");
        }
    })})


})(jQuery); // Fully reference jQuery after this point.
